import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';

import { row, col } from '../library/Grid';

const StyleInfo = css`
  padding-top: 8px;
  padding-bottom: 8px;
  ${row};
`;

const StyleInfoGroup = css`
  @media screen and (max-width: 1279px) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  @media screen and (min-width: 1280px) {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  ${col};
`;

const StyleInfoRow = css`
  margin-left: -4px;
  margin-right: -4px;
`;

const StyleInfoColumn = css`
  display: inline-block;
  vertical-align: top;
  padding-left: 4px;
  padding-right: 4px;
`;

const StyleTextBreak = css`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
`;

const StyleInfoText = css`
  font-size: 14px;
  line-height: 1.143;
  font-weight: 400;
  letter-spacing: 0.14px;
  ${StyleTextBreak};
`;

const StyleInfoTextKey = css`
  color: #8d99ae;
`;

const StyleInfoTextVal = css`
  color: #59667d;
`;

const StyledInfo = styled('div')`
  ${StyleInfoText}
  ${StyleInfoTextKey}
  ${props => props.textColor ? 'color: ' + props.textColor : ''}
`;

class ProductInfo extends Component {
  render() {
    const quantity = this.props.quantity;

    const vcode = this.props.vcode;
    const vcodeName = !!vcode && 'name' in vcode ? vcode.name : '';
    const vcodeNumber = !!vcode && 'number' in vcode ? vcode.number : '';
    const order = this.props.order;
    const orderTitle = !!order && 'title' in order ? order.title : '';
    const delivery = this.props.delivery;
    const deliveryTitle =
      !!delivery && 'title' in delivery ? delivery.title : '';
    const deliveryDate = !!delivery && 'date' in delivery ? delivery.date : '';

    return (
      <Fragment>
        <div className={StyleInfo}>
          {vcodeNumber ? (
            <div className={StyleInfoGroup}>
              <div className={StyleInfoRow}>
                <div className={StyleInfoColumn}>
                  <div className={`${StyleInfoText} ${StyleInfoTextKey}`}>
                    {vcodeName}
                  </div>
                </div>
                <div className={StyleInfoColumn}>
                  <div className={`${StyleInfoText} ${StyleInfoTextVal}`}>
                    {vcodeNumber}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {order && (
            <div className={StyleInfoGroup}>
              <div className={StyleInfoRow}>
                <div className={StyleInfoColumn}>
                  <div className={`${StyleInfoText} ${StyleInfoTextVal}`}>
                    {orderTitle}
                  </div>
                </div>
              </div>
            </div>
          )}
          {quantity ? (
            <div className={StyleInfoGroup}>
              <div className={StyleInfoRow}>
                {quantity.value > '0' ? (
                  <Fragment>
                    <div className={StyleInfoColumn}>
                      <StyledInfo textColor={ this.props.textColor }>
                        В наличии:
                      </StyledInfo>
                    </div>
                    <div className={StyleInfoColumn}>
                      <div className={`${StyleInfoText} ${StyleInfoTextVal}`}>
                        {quantity.value}
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <div className={StyleInfoColumn}>
                    <StyledInfo textColor={ this.props.textColor }>
                      Нет в наличии
                    </StyledInfo>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ''
          )}
          {delivery ? (
            <div className={StyleInfoGroup}>
              <div className={StyleInfoRow}>
                <div className={StyleInfoColumn}>
                  <div className={`${StyleInfoText} ${StyleInfoTextKey}`}>
                    {deliveryTitle}
                  </div>
                </div>
                <div className={StyleInfoColumn}>
                  <div className={`${StyleInfoText} ${StyleInfoTextVal}`}>
                    {deliveryDate}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      </Fragment>
    );
  }
}

ProductInfo.propTypes = {
  vcode: PropTypes.object,
  order: PropTypes.object,
  delivery: PropTypes.object
};

export { ProductInfo };
