import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import { row, col_xs_12, col_md_6 } from '../library/Grid';
import ToCartActiveButton from './ToCartActiveButton';
import Actions from '@common/data/Actions';

const StyleButtonBlock = css`
  padding-top: 8px;
  padding-bottom: 8px;
  ${row};
`;

const StyleTextBreak = css`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
`;

const StyleButtonColumn = css`
  padding-top: 8px;
  padding-bottom: 8px;
  ${col_xs_12};
  ${col_md_6};
`;

const StyleButton = css`
  cursor: pointer;
  display: inline-block;
  width: 100%;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  border-radius: 32px;
  border: 0;
  box-sizing: border-box;
  outline: none;
  transition: 0.1s ease-out background;
  @media screen and (max-width: 1023px) {
    padding: 16px 24px;
    font-size: 14px;
    line-height: 1.143;
    letter-spacing: 0.7px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding: 12px 16px;
    font-size: 12px;
    line-height: 1.334;
    letter-spacing: 0.12px;
  }
  @media screen and (min-width: 1280px) {
    padding: 16px 30px;
    font-size: 13px;
    line-height: 1.231;
    letter-spacing: 0.13px;
  }
  ${StyleTextBreak};
`;

 const StyleButtonBasket = css`
   ${StyleButton};
   background: #26c485;
   color: #fff;
   &:hover,
   &:focus {
     background: #29d28f;
   }
 `;

const StyleButtonBasketDIV= styled('div')`
  ${StyleButtonBasket}
  background-color: ${props => props.backgroundColor };
  &:hover, &:focus { background-color: ${props => props.focusBackgroundColor };}
`;

class ProductButtonAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inCart: 0,
      cartInput: {},
      isShowMessage: false
    };
  }

  componentDidMount() {
    const inCart = JSON.parse(localStorage.getItem('inCart')) || {};
    let cartInput = JSON.parse(localStorage.getItem('cartInput'));
    this.setState({
      inCart: inCart[this.props.productId] || 0,
      cartInput: cartInput
    });
  }

  handleClick() {
      this.showSuccess();
    let cartInput = JSON.parse(localStorage.getItem('cartInput'));
    if (!cartInput) {
      this.props.createCart({}).then(res => {
        console.log(res.data.createCart);
        cartInput = {
          id: res.data.createCart.id,
          cartToken: res.data.createCart.cart_token,
          options: {
            cartPageURL: res.data.createCart.options.cartPageURL
          }
        };
        localStorage.setItem('cartInput', JSON.stringify(cartInput));

        this.addProduct(cartInput);
      });
    } else {
      this.addProduct(cartInput);
    }

  }

  addProduct(cartInput) {
    this.props
      .addProduct({
        variables: {
          cartInput,
          product: {
            id: this.props.productId,
            quantity: this.state.inCart + 1
          }
        }
      })
      .then(res => {
        console.log(res);
      });

    this.setState(
      prevState => {
        return { inCart: prevState.inCart + 1, cartInput };
      },
      () => {
        this.updateProductCounter();
        Actions.cartUpdate();
      }
    );
  }

  updateProductCounter() {
    const inCart = JSON.parse(localStorage.getItem('inCart')) || {};
    inCart[this.props.productId] = this.state.inCart;
    localStorage.setItem('inCart', JSON.stringify(inCart));
  }

  showSuccess() {
    this.setState({
      isShowMessage: true
    });
    setTimeout(() => {
      this.setState({
        isShowMessage: false
      });
    }, 2000);
  }

  getButtonText() {
      const balance  = this.props.balance || 0;
      const order    = balance <= 0;
      const customProps = this.props?.customProps;
      if (order) {
        return customProps?.orderLabel ? customProps?.orderLabel : this.props.orderDefaultLabel || '';
      }

      return customProps?.inCartLabel ? customProps?.inCartLabel : this.props.inCartDefaultLabel || '';
  }

  render() {
    if (!this.props.cart.id) {
      return null;
    }

    const customProps = this.props?.customProps;
    return (
      <div className={StyleButtonBlock}>
        <div className={StyleButtonColumn}>
          <StyleButtonBasketDIV
              backgroundColor={customProps?.color}
              focusBackgroundColor={this.state.isShowMessage ? customProps?.color : customProps?.focusColor}
              onClick={this.handleClick.bind(this)}
          >
              { this.state.isShowMessage ? this.props.successLabel : this.getButtonText() }
          </StyleButtonBasketDIV>
        </div>
      </div>
    );
  }
}

ProductButtonAdd.propTypes = {
  cart: PropTypes.object,
  balance: PropTypes.string,
  underOrder: PropTypes.bool,
  productId: PropTypes.string.isRequired,
  createCart: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
  orderDefaultLabel: PropTypes.string,
  inCartDefaultLabel: PropTypes.string,
  successLabel: PropTypes.string,
  customProps: PropTypes.shape({
      color: PropTypes.string,
      focusColor: PropTypes.string,
      orderLabel: PropTypes.string,
      inCartLabel: PropTypes.string,
  }),
};


export default ProductButtonAdd;
