import React from 'react';
import styled, { css } from 'react-emotion';
import PropTypes from 'prop-types';

const ActiveBtnBlock = css`
  display: flex;
  align-items: center;
`;

const ActiveBtn = css`
  outline: none;
  border: none;
  font-weight: 500;
  cursor: pointer;
  line-height: 16px;
  @media screen and (max-width: 1023px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @media screen and (min-width: 1280px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const ActiveBtnCart = css`
  flex: auto;
  background: #ddf7ea;
  color: #31c07e;
  text-transform: uppercase;
  text-align: left;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  @media screen and (max-width: 1023px) {
    padding-left: 24px;
    font-size: 14px;
    letter-spacing: 0.14px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 16px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: 12px;
    letter-spacing: 0.12px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 13px;
    letter-spacing: 0.13px;
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
  }
  &:hover {
    color: #fff;
    background: #29d28f;
  }
  &:active {
    color: #fff;
    background: #42da9e;
  }
`;

const ActiveBtnInCart = css`
  color: #202c39;
`;

const ActiveBtnPlusItem = css`
  font-size: 16px;
  letter-spacing: 0.16px;
  color: #fff;
  background: #26c485;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
  @media screen and (max-width: 1023px) {
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
    letter-spacing: 0.16px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 16px;
    padding-right: 16px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: 14px;
    letter-spacing: 0.14px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 16px;
    letter-spacing: 0.16px;
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
  }
  &:hover {
    background: #29d28f;
  }
  &:active {
    background: #42da9e;
  }
`;

const AddOneToCartButton = styled('div')`
    ${ActiveBtn}
    ${ActiveBtnPlusItem}
    ${props => props.backgroundColor ? "background-color:" + props.backgroundColor : ""};
    ${props => props.focusBackgroundColor ? " &:hover, &:focus { background-color:" + props.focusBackgroundColor + " }" : ""};
`;

const InTheCartButton = styled('a')`
    ${ActiveBtn}
    ${ActiveBtnCart}
    text-decoration: none;
    ${props => props.backgroundColor ? "background-color:" + props.backgroundColor : ""};
    ${props => props.focusBackgroundColor ? " &:hover, &:focus { background-color:" + props.focusBackgroundColor + " }" : ""};
`;

class ToCartActiveButton extends React.Component {
  static propTypes = {
    cartUrl: PropTypes.string,
    addToCart: PropTypes.func,
    quantity: PropTypes.number
  };

  render() {
    return (
        <div className={ActiveBtnBlock}>
            <InTheCartButton
                backgroundColor     ={this.props.inTheCartButtonBackgroundColor}
                focusBackgroundColor={this.props.inTheCartButtonFocusBackgroundColor}
                href                ={this.props.cartUrl}
            >
               В корзине:{' '}
               <span
                   className={ActiveBtnInCart}>{this.props.quantity}</span>
            </InTheCartButton>
            <AddOneToCartButton
                backgroundColor     ={this.props.addOneToCartButtonBackgroundColor}
                focusBackgroundColor={this.props.addOneToCartButtonFocusBackgroundColor}
                onClick             ={this.props.addToCart}
            > + 1 </AddOneToCartButton>
        </div>
    );
  }
}

export default ToCartActiveButton;
