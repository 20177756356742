import gql from "graphql-tag";
import { BackgroundFragment } from "@common/graphql/fragment";

const GET_FRAGMENT = gql`
  query pageFragment($id: ID) {
      fragmentPayload(id: $id)
      @rest(type: "FragmentPayload", path: "/:id", endpoint: "fragment") {
      fragment @type(name: "FragmentPayloadData") {
        id
        props @type(name: "Props") @edit(as:"Panel") {
          block @edit(label:"Блок") @type(name: "Fragment") {
            backgroundColor @edit(as:"Color" label:"Цвет фона" value:"#fff")
            titleTextColor @edit(as: Color label:"Цвет наименования" value:"#202c39")
            priceColor @edit(as: Color label:"Цвет цены" value:"#202c39")
            descriptionTextColor @edit(as: Color label:"Цвет описания" value:"#59667d")
          }

          button @edit(label:"Кнопка") @type(name: "Fragment") {
            color @edit(as:"Color" label:"Цвет" value:"#26c485")
            focusColor @edit(as:"Color" label:"Цвет при наведении" value:"#29d28f")
            inCartLabel @edit(as:"SimpleText" label:"Текст на кнопке, когда товар в наличии" value: "в корзину")
            orderLabel @edit(as:"SimpleText" label:"Текст на кнопке, когда товар доступен под заказ" value: "заказать")
          }
        }
      }
    }
  }
  ${BackgroundFragment}
`;

export default GET_FRAGMENT;
