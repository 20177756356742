import gql from "graphql-tag";
import { graphql, compose } from "react-apollo";
import { mapProps } from "recompose";
import { Product as ProductView } from "./../Product";
import GET_BACKGROUND_FRAGMENT from "../../fragment";
import { withMainEntitySubscription } from "@common/hoc/withMainEntitySubscription";
import { withFragmentEntitySubscription } from "@common/hoc/withFragmentEntitySubscription";

const CREATE_CART = gql`
  mutation createCart {
    createCart {
      id
      cart_token
      options {
        cartPageURL
      }
    }
  }
`;

const CHANGE_QUANTITY_PRODUCT = gql`
  mutation changeQuantityProduct(
    $cartInput: CartInput
    $product: ProductQuantityInput
  ) {
    changeQuantityProductInCart(cart: $cartInput, product: $product) {
      id
    }
  }
`;

export const ImageFragment = gql`
  fragment ImageFragment on Image {
    id
    alt
    title
    thumbnail(
      formats: [
        { id: "160x120", width: 160, height: 120 }
        { id: "544x408", width: 544, height: 408 }
      ]
    ) {
      format_id
      url
    }
  }
`;

export const GalleryFragment = gql`
  fragment GalleryFragment on Gallery {
    id
    media {
      entities {
        ... on Image {
          ...ImageFragment
        }
      }
    }
  }
  ${ImageFragment}
`;

export const PreviewFragment = gql`
  fragment PreviewFragment on Image {
    id
    title
    url
    thumbnail(formats: [{ id: "544x408", width: 544, height: 408 }]) {
      format_id
      url
    }
  }
`;

const ProductFragment = gql`
  fragment ProductFragment on Product {
    id
    title
    description
    taggedBy {
      id
      name
      color
    }
    listPrice {
      formattedValue
      currency
    }
    netPrice {
      formattedValue
      currency
    }
    sku
    quantity {
      value
    }
    preview {
      ... on Image {
        ...PreviewFragment
      }
    }
    gallery {
      ...GalleryFragment
    }
  }
  ${GalleryFragment}
  ${PreviewFragment}
`;

const PRODUCT_QUERY = gql`
  query getProduct($id: ID!) {
    product: node(id: $id) {
      ...ProductFragment
    }
    site {
      id
      modulesConnection {
        edges {
          node {
            __typename
            ... on CartModule {
              id
            }
            ... on CatalogModule {
              id
            }
          }
        }
      }
    }
  }
  ${ProductFragment}
`;

const ProductWithMainEntitySubscription = withMainEntitySubscription(
  ProductView
);

const ProductWithFragmentEntitySubscription = withFragmentEntitySubscription(
  ProductWithMainEntitySubscription
);

const ProductViewWithEditableProps = mapProps(props => {
  return {
    ...props,
    ...props.fragment.fragmentPayload.fragment.props
  };
})(ProductWithFragmentEntitySubscription);

export default compose(
  graphql(CREATE_CART, {
    name: "createCart"
  }),
  graphql(CHANGE_QUANTITY_PRODUCT, {
    name: "addProduct"
  }),
  graphql(PRODUCT_QUERY, {
    options: props => {
      const { id } = props.rootContext;

      return {
        variables: {
          id
        }
      };
    },
    props: ({ data }) => ({ ...data })
  }),
  graphql(GET_BACKGROUND_FRAGMENT, {
    name: "fragment",
    options: props => {
      return {
        variables: {
          id: props.fragmentEntity.id
        },
        errorPolicy: "ignore"
      };
    }
  })
)(ProductViewWithEditableProps);
