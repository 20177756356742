import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'react-emotion';
import Slider from 'react-slick';

const StyleSlider = css`
  .slick-slider,
  .slick-list,
  .slick-track {
    position: relative;
    display: block;
  }

  .slick-slider {
    padding-top: 8px;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-slider .slick-list,
  .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
  }

  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-list {
    overflow: hidden;
    margin: 0;
    padding-bottom: 16px;
    text-align: center;
  }

  .slick-list:focus {
    outline: 0;
  }

  .slick-list.dragging {
    cursor: pointer;
  }

  .slick-track {
    top: 0;
    left: 0;
  }

  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }

  .slick-track:after {
    clear: both;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }

  .slick-slide img {
    display: block;
  }

  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;

const StyleBigImage = css`
  vertical-align: top;
  outline: none;
`;

const StyleImage = css`
  max-width: 100%;
  height: auto;
  vertical-align: top;
`;

const StyleThumbList = css`
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 1023px) {
    margin-left: -8px;
    margin-right: -8px;
  }
  @media screen and (min-width: 1024px) {
    margin-left: -16px;
    margin-right: -16px;
  }
  @media screen and (max-width: 1279px) {
    padding-top: 8px;
  }
  @media screen and (min-width: 1280px) {
    padding-top: 16px;
  }

  li {
    padding-bottom: 32px;
    text-align: center;
    box-sizing: border-box;
    vertical-align: top;

    @media screen and (max-width: 767px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @media screen and (min-width: 768px) {
      flex: 0 0 33.334%;
      max-width: 33.334%;
    }
    @media screen and (max-width: 1023px) {
      padding: 0 8px 16px;
    }
    @media screen and (min-width: 1024px) {
      padding: 0 16px 32px;
    }
  }
  .slick-active a {
    opacity: 0.7;
  }
`;

const StyleThumbLink = css`
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  transition: opacity ease-out 0.1s;

  &:hover {
    opacity: 0.7;
  }
`;

class ProductGallery extends React.Component {
  state = {
    images: []
  };

  constructor(props) {
    super(props);
    let { gallery } = props;
    this.state = {
      images: gallery.media.entities
    };
  }

  componentWillReceiveProps(props) {
    let { gallery } = props;
    this.setState({
      images: gallery.media.entities
    });
  }

  thumbnailUrl = (image, format) => {
    let items = {};
    ((image || { thumbnail: [] }).thumbnail || []).map(value => {
      items[value.format_id] = value;
    });
    return (
      items[format] || {
        url:
          'https://i.siteapi.org/eWWN8l6GGrVyJNOJ-HUYfA_rRmU=/1f31899a95cc470.s2.siteapi.org/blockimage/7j2cz2c7mlssgsogg4cggsckcgs4ww'
      }
    ).url;
  };

  preview(i) {
    const item = this.state.images[i] || {};
    return (
      <a className={StyleThumbLink}>
        <img
          className={StyleImage}
          src={this.thumbnailUrl(item, '160x120')}
          alt={item.alt}
          title={item.title}
        />
      </a>
    );
  }

  render() {
    let { gallery } = this.props;
    let entities = gallery.media.entities || [];

    const dots = dots => (
      <div>
        <ul className={StyleThumbList}>{dots}</ul>
      </div>
    );
    const settings = {
      customPaging: this.preview.bind(this),
      dots: true,
      appendDots: dots.bind(this),
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
      arrows: false,
      fade: true
    };

    return (
      <div className={StyleSlider}>
        <Slider {...settings}>
          {entities.map(item => (
            <div key={item.id} className={StyleBigImage}>
              <img
                className={StyleImage}
                src={this.thumbnailUrl(item, '544x408')}
                alt={item.alt}
                title={item.title}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

ProductGallery.propTypes = {
  gallery: PropTypes.object
};

export { ProductGallery };
