import React from 'react';
import PropTypes from 'prop-types';
import { Product as ViewProduct } from './components/Product';
import {
  FloatPanel,
  FrameEditor,
  FrameEditorContent
} from '@common/components/FloatPanel';

const Product = props => {
  const { loading, product, site, rootContext } = props;
  if (loading || !site) {
    return <h1>Загрузка</h1>;
  }

  let catalog = {};
  site.modulesConnection.edges.map(({ node: item }) => {
    if (item.__typename === 'CatalogModule') {
      catalog = item;
    }
  });

  if (rootContext.mode !== 'editor') {
    return <ViewProduct {...props} />;
  }

  return (
    <FrameEditor>
      <FloatPanel
        blockId={rootContext.blockId}
        link={{
          edit: `/admin/catalog/edit-product/${catalog.id}/${product.id}`,
          editFragment: `/admin/fragment/${props.fragmentEntity.id}`
        }}
        actions={['delete', 'editFragment']}
      />
      <FrameEditorContent>
        <ViewProduct {...props} />
      </FrameEditorContent>
    </FrameEditor>
  );
};

Product.propTypes = {
  product: PropTypes.object,
  site: PropTypes.object,
  loading: PropTypes.bool,
  rootContext: PropTypes.object
};

export { Product };
