import gql from "graphql-tag";

const BackgroundFragment = gql`
  fragment BackgroundFragment on Background {
    color
    image
  }
`;

const SimpleTextFragment = gql`
  fragment SimpleTextFragment on SimpleText {
    value
  }
`;

export { BackgroundFragment, SimpleTextFragment };
