import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

const StyleMarkerAll = css`
  margin: 0 -4px;
  padding: 8px 0;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
`;

const StyleMarkerItem = css`
  display: inline-block;
  padding: 0 4px 8px 4px;
`;

const StyleTextBreak = css`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
`;

const StyleMarker = css`
  display: inline-block;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  @media screen and (max-width: 1023px) {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1.167;
    letter-spacing: 0.6px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding: 8px 8px;
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 0.5px;
  }
  @media screen and (min-width: 1280px) {
    padding: 8px 9px;
    font-size: 11px;
    line-height: 1.1;
    letter-spacing: 0.55px;
  }
  ${StyleTextBreak};
`;

class ProductMarker extends Component {
  render() {
    const tags = this.props.tags;

    return (
      <Fragment>
        {tags ? (
          <div className={StyleMarkerAll}>
            {tags.map(tag => (
              <div key={tag.id} className={StyleMarkerItem}>
                <div
                  className={`${StyleMarker} ${css({
                    backgroundColor: tag.color
                  })}`}
                >
                  {tag.name}
                </div>
              </div>
            ))}
          </div>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

ProductMarker.propTypes = {
  tags: PropTypes.array
};

export { ProductMarker };
