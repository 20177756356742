import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import styled from 'react-emotion';
import { row, col_xs_12 } from '../library/Grid';

const StyleDesc = css`
  padding-top: 16px;
  padding-bottom: 16px;
  ${row};
`;

const StyleDescColumn = css`
  ${col_xs_12};
`;

const StyleTextBreak = css`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
`;

const StyleDescText = css`
  font-weight: 400;
  @media screen and (max-width: 1279px) {
    font-size: 14px;
    line-height: 1.715;
    letter-spacing: 0.14px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.16px;
  }
  ${StyleTextBreak};
`;

const StyledDescriptions = styled('div')`
  ${StyleDescText} ${props =>
    props.textColor ? 'color: ' + props.textColor + ';' : 'color: #59667d;'};
`;

class ProductDescription extends React.Component {
  render() {
    const description = this.props.children || '';
    return (
      <Fragment>
        {description.length > 0 ? (
          <div className={StyleDesc}>
            <div className={StyleDescColumn}>
              <StyledDescriptions
                textColor={this.props.textColor}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

ProductDescription.propTypes = {
  children: PropTypes.string
};

export { ProductDescription };
