import React, { Component, Fragment } from 'react';
import { css } from 'emotion';
import styled from 'react-emotion';
import PropTypes from 'prop-types';

const StyleTitle = css`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const StyleTextBreak = css`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
`;

const StyleTitleText = css`
  font-weight: 500;
  @media screen and (max-width: 1023px) {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 0.2px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: 24px;
    line-height: 1.334;
    letter-spacing: 0.24px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 28px;
    line-height: 1.215;
    letter-spacing: 0.28px;
  }
  ${StyleTextBreak};
`;


const StyledTitleText = styled('div')`
  ${props => props.textColor ? 'color: ' + props.textColor + ';' : 'color: #202c39;'}
  ${StyleTitleText}
`;

class ProductTitle extends React.Component {
  render() {
    const title = this.props.children || '';

    return (
      <Fragment>
        {title ? (
          <div className={StyleTitle}>
            <StyledTitleText textColor={this.props.textColor}>
                {title}
            </StyledTitleText>
          </div>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

ProductTitle.propTypes = {
  children: PropTypes.string
};

export { ProductTitle };
