import { css } from 'react-emotion';

const container = () => css`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  @media screen and (max-width: 1023px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const row = () => css`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1023px) {
    margin-left: -8px;
    margin-right: -8px;
  }
  @media screen and (min-width: 1024px) {
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const column = () => css`
  box-sizing: border-box;
  @media screen and (max-width: 1023px) {
    padding-left: 8px;
    padding-right: 8px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

// Ширина колонок

const col = () => css`
  display: inline-block;
  vertical-align: top;
  ${column};
`;

const col_1 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 8.3%;
  max-width: 8.3%;
  ${column};
`;

const col_2 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 16.6%;
  max-width: 16.6%;
  ${column};
`;

const col_3 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 25%;
  max-width: 25%;
  ${column};
`;

const col_4 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 33.334%;
  max-width: 33.334%;
  ${column};
`;

const col_5 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 41.6%;
  max-width: 41.6%;
  ${column};
`;

const col_6 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 50%;
  max-width: 50%;
  ${column};
`;

const col_7 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 58.2%;
  max-width: 58.2%;
  ${column};
`;

const col_8 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 66.5%;
  max-width: 66.5%;
  ${column};
`;

const col_9 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 74.8%;
  max-width: 74.8%;
  ${column};
`;

const col_10 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 83.1%;
  max-width: 83.1%;
  ${column};
`;

const col_11 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 91.4%;
  max-width: 91.4%;
  ${column};
`;

const col_12 = () => css`
  -webkit-box-flex: 0;
  flex: 0 0 100%;
  max-width: 100%;
  ${column};
`;

// Колонки для XSMALL

const col_xs_1 = () => css`
  ${col_1};
`;

const col_xs_2 = () => css`
  ${col_2};
`;

const col_xs_3 = () => css`
  ${col_3};
`;

const col_xs_4 = () => css`
  ${col_4};
`;

const col_xs_5 = () => css`
  ${col_5};
`;

const col_xs_6 = () => css`
  ${col_6};
`;

const col_xs_7 = () => css`
  ${col_7};
`;

const col_xs_8 = () => css`
  ${col_8};
`;

const col_xs_9 = () => css`
  ${col_9};
`;

const col_xs_10 = () => css`
  ${col_10};
`;

const col_xs_11 = () => css`
  ${col_11};
`;

const col_xs_12 = () => css`
  ${col_12};
`;

// Колонки для SMALL

const col_sm_1 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_1};
    }
  `;
const col_sm_2 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_2};
    }
  `;
const col_sm_3 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_3};
    }
  `;
const col_sm_4 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_4};
    }
  `;
const col_sm_5 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_5};
    }
  `;
const col_sm_6 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_6};
    }
  `;
const col_sm_7 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_7};
    }
  `;
const col_sm_8 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_8};
    }
  `;
const col_sm_9 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_9};
    }
  `;
const col_sm_10 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_10};
    }
  `;
const col_sm_11 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_11};
    }
  `;
const col_sm_12 = () =>
  css`
    @media screen and (min-width: 768px) {
      ${col_12};
    }
  `;

// Колонки для MEDIUM

const col_md_1 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_1};
    }
  `;
const col_md_2 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_2};
    }
  `;
const col_md_3 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_3};
    }
  `;
const col_md_4 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_4};
    }
  `;
const col_md_5 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_5};
    }
  `;
const col_md_6 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_6};
    }
  `;
const col_md_7 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_7};
    }
  `;
const col_md_8 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_8};
    }
  `;
const col_md_9 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_9};
    }
  `;
const col_md_10 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_10};
    }
  `;
const col_md_11 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_11};
    }
  `;
const col_md_12 = () =>
  css`
    @media screen and (min-width: 1024px) {
      ${col_12};
    }
  `;

// Колонки для LARGE

const col_lg_1 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_1};
    }
  `;
const col_lg_2 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_2};
    }
  `;
const col_lg_3 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_3};
    }
  `;
const col_lg_4 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_4};
    }
  `;
const col_lg_5 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_5};
    }
  `;
const col_lg_6 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_6};
    }
  `;
const col_lg_7 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_7};
    }
  `;
const col_lg_8 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_8};
    }
  `;
const col_lg_9 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_9};
    }
  `;
const col_lg_10 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_10};
    }
  `;
const col_lg_11 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_11};
    }
  `;
const col_lg_12 = () =>
  css`
    @media screen and (min-width: 1280px) {
      ${col_12};
    }
  `;

export {
  container,
  row,
  column,
  col,
  col_xs_1,
  col_xs_2,
  col_xs_3,
  col_xs_4,
  col_xs_5,
  col_xs_6,
  col_xs_7,
  col_xs_8,
  col_xs_9,
  col_xs_10,
  col_xs_11,
  col_xs_12,
  col_sm_1,
  col_sm_2,
  col_sm_3,
  col_sm_4,
  col_sm_5,
  col_sm_6,
  col_sm_7,
  col_sm_8,
  col_sm_9,
  col_sm_10,
  col_sm_11,
  col_sm_12,
  col_md_1,
  col_md_2,
  col_md_3,
  col_md_4,
  col_md_5,
  col_md_6,
  col_md_7,
  col_md_8,
  col_md_9,
  col_md_10,
  col_md_11,
  col_md_12,
  col_lg_1,
  col_lg_2,
  col_lg_3,
  col_lg_4,
  col_lg_5,
  col_lg_6,
  col_lg_7,
  col_lg_8,
  col_lg_9,
  col_lg_10,
  col_lg_11,
  col_lg_12
};
