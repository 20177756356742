import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import styled from 'react-emotion';

import FormattedCurrency  from '@common/components/LocaleFormatter';

const StylePrice = css`
  padding-top: 8px;
  padding-bottom: 8px;
  @media screen and (max-width: 1279px) {
    margin-left: -8px;
    margin-right: -8px;
  }
  @media screen and (min-width: 1280px) {
    margin-left: -12px;
    margin-right: -12px;
  }
`;

const StyleTextBreak = css`
  word-break: break-word;
  word-wrap: break-word;
  hyphens: manual;
`;

const StyleNewPrice = css`
  display: inline-block;
  vertical-align: top;
  @media screen and (max-width: 1279px) {
    padding: 8px;
  }
  @media screen and (min-width: 1280px) {
    padding: 10px 12px;
  }
`;

const StyleNewPriceRow = css`
  @media screen and (max-width: 1023px) {
    margin-left: -3px;
    margin-right: -3px;
  }
  @media screen and (min-width: 1024px) {
    margin-left: -4px;
    margin-right: -4px;
  }
`;

const StyleNewPriceColumn = css`
  display: inline-block;
  vertical-align: top;
  @media screen and (max-width: 1023px) {
    padding-left: 3px;
    padding-right: 3px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

const StyleNewPriceText = css`
  font-weight: 700;
  color: #202c39;
  @media screen and (max-width: 767px) {
    font-size: 28px;
    line-height: 1.143;
    letter-spacing: -0.56px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 24px;
    line-height: 1.334;
    letter-spacing: -0.48px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: -0.6px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 36px;
    line-height: 1.223;
    letter-spacing: -0.72px;
  }
  ${StyleTextBreak};
`;


const StyledNewPrice = styled('div')`
  ${StyleNewPriceText};
  ${props => props.textColor ? 'color: ' + props.textColor + ';' : ''}
`;

// -----------------------------------------------------------------------------------------------

const StyleOldPrice = css`
  display: inline-block;
  vertical-align: top;
  @media screen and (max-width: 1279px) {
    padding: 8px;
  }
  @media screen and (min-width: 1280px) {
    padding: 8px 12px;
  }
`;

const StyleOldPriceRow = css`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background: #59667d;
  }
`;

const StyleOldPriceColumn = css`
  display: inline-block;
  vertical-align: top;
  padding-right: 4px;
  &:last-child {
    padding-right: 0;
  }
`;

const StyleOldPriceText = css`
  font-weight: 400;
  color: #59667d;
  @media screen and (max-width: 1023px) {
    font-size: 14px;
    line-height: 1.143;
    letter-spacing: 0.14px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.16px;
  }
  @media screen and (min-width: 1280px) {
    font-size: 18px;
    line-height: 1.334;
    letter-spacing: 0.18px;
  }
  ${StyleTextBreak};
`;

class ProductPrice extends Component {
  render() {
    const cart = this.props.cart;
    const price = this.props.price || '';
    const priceSale = this.props.priceSale || '';
    const allPrice = !!cart && !!price && !!priceSale;

    return (
      <Fragment>
        {price ? (
          <div className={StylePrice}>
            <div className={StyleNewPrice}>
              <div className={StyleNewPriceRow}>
                <div className={StyleNewPriceColumn}>
                  <StyledNewPrice textColor={ this.props.priceColor }>
                    <FormattedCurrency value={allPrice ? priceSale : price} />
                  </StyledNewPrice>
                </div>
              </div>
            </div>
            {allPrice && (
              <div className={StyleOldPrice}>
                <div className={StyleOldPriceRow}>
                  <div className={StyleOldPriceColumn}>
                    <div className={StyleOldPriceText}>
                      <FormattedCurrency value={price} />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

ProductPrice.propTypes = {
  cart: PropTypes.object,
  price: PropTypes.string,
  priceSale: PropTypes.string,
  currency: PropTypes.string,
  priceColor: PropTypes.string,
};

export { ProductPrice };
