import React from 'react';
import PropTypes from 'prop-types';

import styled, { css } from 'react-emotion';
import { col_xs_12, col_sm_1, col_sm_5, col_sm_6 } from './../library/Grid';
import { ProductGallery } from './ProductGallery';
import { ProductMarker } from './ProductMarker';
import { ProductTitle } from './ProductTitle';
import { ProductInfo } from './ProductInfo';
import { ProductPrice } from './ProductPrice';
import ProductButtonAdd from './ProductButtonAdd';
import { ProductDescription } from './ProductDescription';
import Store from '@common/data/Store';

const CartStyle = styled('div')`
  font-family: Roboto, sans-serif;
  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    padding: 0 24px;
  }
  background-color: ${props => props.backgroundColor };
`;

const container = css`
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`;

const containerInner = css`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1023px) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media screen and (min-width: 1024px) {
    padding-top: 56px;
    padding-bottom: 48px;
  }
`;

const columnOne = css`
  ${col_xs_12};
  ${col_sm_6};
`;

const columnTwo = css`
  ${col_xs_12};
  ${col_sm_1};
`;

const columnTree = css`
  ${col_xs_12};
  ${col_sm_5};
`;

class Product extends React.Component {
  state = {
    loading: true,
    product: {},
    site: {},
    store: Store,
    rootContext: {}
  };

  constructor(props) {
    super(props);
    const { loading, product, site, rootContext } = props;

    this.state = {
      loading: loading,
      product: product,
      site: site,
      rootContext: rootContext
    };
  }

  componentWillReceiveProps(props) {
    const { loading, product, site } = props;
    
    this.setState({
      loading: loading,
      product: product,
      site: site
    });
  }

  render() {
    const { loading, product, site } = this.state;
    const { createCart, addProduct } = this.props;
    if (loading || !site) {
      return <h1>Загрузка</h1>;
    }

    let cart = {};
    site.modulesConnection.edges.map(({ node: item }) => {
      if (item.__typename === 'CartModule') {
        cart = item;
      }
    });

    const customProps = this.props?.block,
          buttonProps = this.props?.button;
      return (
      <CartStyle backgroundColor={ customProps?.backgroundColor } backgroundImage={ customProps?.backgroundImage }>
        <div className={container}>
          <div className={containerInner}>
            <div className={columnOne}>
              {this.props.product.gallery && (
                <ProductGallery
                  product={this.props.product}
                  gallery={this.props.product.gallery}
                />
              )}
            </div>
            <div className={columnTwo} />
            {product ? (
              <div className={columnTree}>
                {product.taggedBy && <ProductMarker tags={product.taggedBy} />}
                <ProductTitle textColor={customProps?.titleTextColor}>{product.title}</ProductTitle>
                <ProductInfo
                  vcode={{ number: product.sku, name: 'Артикул:' }}
                  order={product.order}
                  delivery={product.delivery}
                  quantity={product.quantity}
                />
                {product.listPrice && (
                  <ProductPrice
                    cart={cart}
                    price={product.listPrice.formattedValue}
                    priceSale={
                      product.netPrice ? product.netPrice.formattedValue : ''
                    }
                    currency={product.listPrice.currency}
                    priceColor={ customProps?.priceColor }
                  />
                )}
                <ProductButtonAdd
                  customProps        = { buttonProps }
                  orderDefaultLabel  = 'заказать'
                  inCartDefaultLabel = 'в корзину'
                  successLabel       = 'товар добавлен'
                  createCart={createCart}
                  addProduct={addProduct}
                  cart={cart}
                  productId={product.id}
                  balance={(product.quantity || {}).value}
                  underOrder={true}
                />
                <ProductDescription textColor={customProps?.descriptionTextColor}>{product.description}</ProductDescription>
              </div>
            ) : (
              <div>Empty</div>
            )}
          </div>
        </div>
      </CartStyle>
    );
  }
}

Product.propTypes = {
  product: PropTypes.object,
  site: PropTypes.object,
  loading: PropTypes.bool,
  rootContext: PropTypes.object,
  refetch: PropTypes.func,
  createCart: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
  block: PropTypes.shape({
      backgroundColor: PropTypes.string,
      backgroundImage: PropTypes.string,
      priceColor: PropTypes.string,
      titleTextColor: PropTypes.string,
      descriptionTextColor: PropTypes.string,
  }),
  button: PropTypes.object,
};

Product.defaultProps = {
  block: {}
};

export { Product };
